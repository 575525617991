import { PortableText } from '@portabletext/react';
import React from 'react';
import getYouTubeId from 'get-youtube-id'
import YouTube from 'react-youtube'
import { getImage, getImageDimensions } from '@sanity/asset-utils';
import { GatsbyImage } from 'gatsby-plugin-image';
import sanityConfig from '../../sanity-config';
import { getSanityImageData } from '../utils/getSanityImageData';

const myPortableTextComponents = {
    block: {
        normal: ({ children }) => <p className="large">{children}</p>,
    },
    list: {
        bullet: ({children}) => <ul className="list-disc text-left mt-base pl-halfbase">{children}</ul>,
        number: ({children}) => <ol className="list-decimal text-left mt-base pl-halfbase">{children}</ol>,
    },
    marks: {
        white: ({children}) => <span className="text-white">{children}</span>,
        orange: ({children}) => <span className="text-orange">{children}</span>,
        strong: ({children}) => <span className="bold">{children}</span>,
        link: ({value, children}) => {
            const target = (value?.href || '').startsWith('http') ? '_blank' : undefined
            return (
                <a href={value?.href} target={target} rel={target === '_blank' && 'noindex nofollow'} className="underlined">
                    {children}
                </a>
            )
        },
    },
    types: {

    },
};

function MyPortableTextLarge({ value }) {
    return <PortableText value={value} components={myPortableTextComponents} />;
}

export default MyPortableTextLarge;


/*
youtube: ({node}) => {
            console.log(node);
            const { url } = node
            const id = getYouTubeId(url)
            return (<YouTube videoId={id} />)
        }
 */
