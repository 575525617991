import React from "react";
import {graphql, Link} from "gatsby";
import Layout from "../components/Layout";
import Marquee from "react-fast-marquee";
import styled from "styled-components";
import Head from "../components/Head";
import CustomSanityImage from "../components/helper/CustomSanityImage";
import LandingImage from "../components/LandingImage";
import EniosolMarquee from "../components/Marquee";
import {GatsbyImage} from "gatsby-plugin-image";
import MyPortableText24 from "../components/MyPortableText24";
import MyPortableTextLarge from "../components/MyPortableTextLarge";


export const query = graphql`
query ($slug: String) {
    sanitySubservices25(slug: {current: {eq: $slug}}) {
        bg {
            hex
        }
        seo{
            seo_title
        }
        images{
            asset{
                gatsbyImageData
            }
        }
        headline
        pageBuilder{
            ... on SanitySubservicestitle25 {
                _type
                titles {
                    title
                    _rawText
                }
            }
            ... on SanitySubservicestext25{
                _type
                texts {
                    title
                    _rawText
                }
            } ... on SanitySubservicesimage25{
                _type
                images {
                    asset {
                        gatsbyImageData
                    }
                }
            }
            ... on SanitySubservicesende25{
                _type
                testimonial{
                    title
                    location
                    _rawText
                }
                buttons{
                    title
                    link
                    color{
                        hex
                    }
                }
            }
            ... on SanitySubservicescta25{
                _type
                headline
                button {
                    title
                    link
                }
            }
            ... on SanityMarquee25 {
                _type
                headline
                color {
                    hex
                }
            }
            ... on SanityLogomarquee25 {
                _key
                _type
                images {
                    asset {
                        gatsbyImageData
                    }
                }
            }
            
        }
  }
  allSanitySettings {
    edges {
      node {
        instalink
        fblink
        linkedinlink
      }
    }
  } 
}
    `;

const ServiceContent = styled.div`
    .service__landing__container{
            background-color: ${({color}) => (color ? color : "#ffffff")}; 
    }
`;

const Service = ({data}) => {
    const service = data.sanitySubservices25;

    console.log(service);
    console.log(service.images[0].asset.gatsbyImageData);

    return (
        <Layout settings={data.allSanitySettings.edges[0].node} landing={true} subfolder={true}>
            <Head title={service?.seo?.seo_title}/>
            <ServiceContent color={service?.bg?.hex}>
                <div className="service__landing__container">
                    <div className="service__landing__image__wrapper">
                        <CustomSanityImage imageData={service.images[0]}></CustomSanityImage>
                        <CustomSanityImage imageData={service.images[1]}></CustomSanityImage>
                        <Marquee gradient={false} className="marquee__landing service" speed={40}>
                            <div className="marqueeText white">
                                <span className="marqueeText__inner uppercase">{service.headline}</span>
                                <span className="marqueeText__inner uppercase">{service.headline}</span>
                                <span className="marqueeText__inner uppercase">{service.headline}</span>
                                <span className="marqueeText__inner uppercase">{service.headline}</span>
                                <span className="marqueeText__inner uppercase">{service.headline}</span>
                                <span className="marqueeText__inner uppercase">{service.headline}</span>
                            </div>
                        </Marquee>
                    </div>
                    <a href={'#overview'}  className="arrowdown__container">
                        <svg xmlns="http://www.w3.org/2000/svg" width="73" height="73" viewBox="0 0 73 73">
                            <g id="Group_103" data-name="Group 103" transform="translate(-924 -975)">
                                <g id="Ellipse_1" data-name="Ellipse 1" transform="translate(924 975)" className="svgoutline">
                                    <circle cx="36.5" cy="36.5" r="36.5" stroke="none"/>
                                    <circle cx="36.5" cy="36.5" r="35" fill="none"/>
                                </g>
                                <g id="Group_12" data-name="Group 12" transform="translate(655 945.5)">
                                    <g id="Group_13" data-name="Group 13">
                                        <line id="Line_1" data-name="Line 1" y2="26.138" transform="translate(305 50)" className="svgarrow"/>
                                        <path id="Path_36" data-name="Path 36" d="M1.061,14.086l11.59,11.59,11.591-11.59" transform="translate(292.349 53)" className="svgarrow"/>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </a>
                </div>
                {service?.pageBuilder?.map((section, index) => {
                    if (section._type === 'marquee25') {
                        return <React.Fragment key={index}>
                            <EniosolMarquee outerClass={`fixedHeight marqueeWrapper !overflow-y-hidden ${section?.color?.hex === '#000000' && 'bgblack'}`} innerClass={`${section?.color?.hex === '#000000' && 'color-white'} ${section?.color?.hex === '#ffffff' && 'color-orange'} uppercase`} content={section?.headline}></EniosolMarquee>
                        </React.Fragment>
                    } else if (section._type === 'subservicescta25') {
                        return <React.Fragment key={index}>
                            <div className="service__ending">
                                <p className="large">{section.headline}</p>
                                <div className={"mt-base mb-base"}>
                                    <Link to={section.button.link} className="btn bold btnservices25 outline orangewhite uppercase">{section.button.title}</Link>
                                </div>
                            </div>
                        </React.Fragment>
                    } else if (section._type === 'subservicestitle25') {
                        return <React.Fragment key={index}>
                            <div className="">
                                <div className={"service__text__container flex"}>
                                    {section?.titles?.map((title, index) => (
                                        <div key={index} className={`w-full tablet:w-1/2`}>
                                            <h6 className="break-words uppercase text-3xl !leading-[.9] laptop:text-5xl large:text-6xl">{title?.title}</h6>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </React.Fragment>
                    } else if (section._type === 'subservicestext25') {
                        return <React.Fragment key={index}>
                            <div className="">
                                <div className={" flex flex-wrap"}>
                                    {section?.texts?.map((title, index) => (
                                        <div key={index} className={`w-full tablet:w-1/2`}>
                                            <div className="service__text__container">
                                                <p className="large mb-base">{title.title}</p>
                                                <MyPortableText24 value={title?._rawText}/>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </React.Fragment>
                    } else if (section._type === 'subservicesimage25') {
                        return <React.Fragment key={index}>
                            <div className="">
                                <div className={"flex flex-wrap"}>
                                    {section?.images?.map((title, index) => (
                                        <div key={index} className={`${section?.images?.length > 1 ? 'w-full tablet:w-1/2' : 'w-full'}`}>
                                            <CustomSanityImage imageData={title}/>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </React.Fragment>
                    } else if(section._type === 'logomarquee25'){
                        return <React.Fragment key={index}>
                            <Marquee gradient={false} className="bgbluegrey marqueeWrapper logomarquee">
                                <div className="marqueeText">
                                    {section.images.map(el => (
                                        <span className="marqueeText__inner"><CustomSanityImage imageData={el} alt="Logo" className="imageround"></CustomSanityImage></span>
                                    ))}
                                    {section.images.map(el => (
                                        <span className="marqueeText__inner"><CustomSanityImage imageData={el} alt="Logo" className="imageround"></CustomSanityImage></span>
                                    ))}
                                </div>
                            </Marquee>
                        </React.Fragment>
                    } else if(section._type === 'subservicesende25'){
                        return <React.Fragment key={index}>
                            <div className="service__testimonial__container color-whiteonblack">
                                <div className="service__testi__left mr-base">
                                    <div className={"text-white"}>
                                        <MyPortableTextLarge value={section?.testimonial?._rawText}/>
                                    </div>
                                    <br/>
                                    <p className="small bold text-white">{section?.testimonial?.title}</p>
                                    <p className="small bold">{section?.testimonial?.ort}</p>
                                </div>
                                <div className="service__testi__right arrowbtn__wrapper mt-halfbase">
                                    {section?.buttons?.map((button, index) => {
                                        return <Link to={button?.link} className="mb-[5px] btn !p-[5px] flex items-center smalltext bold filled uppercase !w-full max-w-[400px]" style={{background: button?.color?.hex}}>
                                            <svg xmlns="http://www.w3.org/2000/svg" className={"!h-[45px] !w-auto"} width="53" height="53" viewBox="0 0 53 53">
                                                <g id="Group_430" data-name="Group 430" transform="translate(-1290 -1780)">
                                                    <g id="Ellipse_2" data-name="Ellipse 2" transform="translate(1290 1780)" fill="none" stroke="#fff" stroke-width="3">
                                                        <circle cx="26.5" cy="26.5" r="26.5" stroke="none"/>
                                                        <circle cx="26.5" cy="26.5" r="25" fill="none"/>
                                                    </g>
                                                    <g id="Group_23" data-name="Group 23" transform="translate(1302.162 1819.429) rotate(-90)">
                                                        <g id="Group_13" data-name="Group 13">
                                                            <line id="Line_1" data-name="Line 1" y2="26.138" transform="translate(11.59)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
                                                            <path id="Path_36" data-name="Path 36" d="M0,0,11.59,11.59,23.181,0" transform="translate(0 17.086)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>
                                            <p className=" bold largesection ml-[10px]">{button?.title}</p>
                                        </Link>
                                    })}
                                </div>
                            </div>
                        </React.Fragment>
                    }
                })}
                    {/*<Marquee gradient={false} className="marqueeWrapper fixedHeight" speed={40}>
                    <div className="marqueeText color-orange">
                        <span className="marqueeText__inner">{service.marqueetext}</span>
                        <span className="marqueeText__inner">{service.marqueetext}</span>
                        <span className="marqueeText__inner">{service.marqueetext}</span>
                        <span className="marqueeText__inner">{service.marqueetext}</span>
                        <span className="marqueeText__inner">{service.marqueetext}</span>
                        <span className="marqueeText__inner">{service.marqueetext}</span>
                        <span className="marqueeText__inner">{service.marqueetext}</span>
                        <span className="marqueeText__inner">{service.marqueetext}</span>
                    </div>
                </Marquee>

                <div className="services__container" id={"overview"}>
                    {service.service.map(el => (
                        <div className="service__wrapper">
                            {console.log(el)}
                            <GatsbyImage alt={el.title} image={el.image.asset.gatsbyImageData} className="service__image"></GatsbyImage>
                            <div className="service__text__container">
                                <p className="large">{el.title}</p>
                                <p className="small">{el.text}</p>
                            </div>

                        </div>

                    ))}

                </div>
                <div className="service__ending">
                    <p className="large">{service.serviceendetext}</p>
                </div>
                <div className="service__testimonial__container color-whiteonblack">
                    <div className="service__testi__left">
                        <p className="large einzelservice">{service.testimonial.text}</p>
                        <br/>
                        <p className="small bold">{service.testimonial.title}</p>
                        <p className="small bold">{service.testimonial.ort}</p>
                    </div>
                    <div className="service__testi__right arrowbtn__wrapper">
                        <Link to={'/service'} className="btn arrowbtn bluegrey smalltext bold filled">
                            <svg xmlns="http://www.w3.org/2000/svg" width="53" height="53" viewBox="0 0 53 53">
                                <g id="Group_430" data-name="Group 430" transform="translate(-1290 -1780)">
                                    <g id="Ellipse_2" data-name="Ellipse 2" transform="translate(1290 1780)" fill="none" stroke="#fff" stroke-width="3">
                                        <circle cx="26.5" cy="26.5" r="26.5" stroke="none"/>
                                        <circle cx="26.5" cy="26.5" r="25" fill="none"/>
                                    </g>
                                    <g id="Group_23" data-name="Group 23" transform="translate(1302.162 1819.429) rotate(-90)">
                                        <g id="Group_13" data-name="Group 13">
                                            <line id="Line_1" data-name="Line 1" y2="26.138" transform="translate(11.59)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
                                            <path id="Path_36" data-name="Path 36" d="M0,0,11.59,11.59,23.181,0" transform="translate(0 17.086)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                            <p className="small bold">PHOTOVOLTAIK PLANEN</p>
                        </Link>
                        <Link to={'/#energierechner'} className="btn arrowbtn green smalltext bold filled">
                            <svg xmlns="http://www.w3.org/2000/svg" width="53" height="53" viewBox="0 0 53 53">
                                <g id="Group_430" data-name="Group 430" transform="translate(-1290 -1780)">
                                    <g id="Ellipse_2" data-name="Ellipse 2" transform="translate(1290 1780)" fill="none" stroke="#fff" stroke-width="3">
                                        <circle cx="26.5" cy="26.5" r="26.5" stroke="none"/>
                                        <circle cx="26.5" cy="26.5" r="25" fill="none"/>
                                    </g>
                                    <g id="Group_23" data-name="Group 23" transform="translate(1302.162 1819.429) rotate(-90)">
                                        <g id="Group_13" data-name="Group 13">
                                            <line id="Line_1" data-name="Line 1" y2="26.138" transform="translate(11.59)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
                                            <path id="Path_36" data-name="Path 36" d="M0,0,11.59,11.59,23.181,0" transform="translate(0 17.086)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                            <p className="small bold">PERS. STROMPREISE BERECHNEN</p>
                        </Link>
                        <Link to={'/kontakt'} className="btn arrowbtn pink smalltext bold filled">
                            <svg xmlns="http://www.w3.org/2000/svg" width="53" height="53" viewBox="0 0 53 53">
                                <g id="Group_430" data-name="Group 430" transform="translate(-1290 -1780)">
                                    <g id="Ellipse_2" data-name="Ellipse 2" transform="translate(1290 1780)" fill="none" stroke="#fff" stroke-width="3">
                                        <circle cx="26.5" cy="26.5" r="26.5" stroke="none"/>
                                        <circle cx="26.5" cy="26.5" r="25" fill="none"/>
                                    </g>
                                    <g id="Group_23" data-name="Group 23" transform="translate(1302.162 1819.429) rotate(-90)">
                                        <g id="Group_13" data-name="Group 13">
                                            <line id="Line_1" data-name="Line 1" y2="26.138" transform="translate(11.59)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
                                            <path id="Path_36" data-name="Path 36" d="M0,0,11.59,11.59,23.181,0" transform="translate(0 17.086)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                            <p className="small bold">TERMIN VEREINBAREN</p>
                        </Link>
                        <Link to={'/blog'} className="btn arrowbtn orange smalltext bold filled">
                            <svg xmlns="http://www.w3.org/2000/svg" width="53" height="53" viewBox="0 0 53 53">
                                <g id="Group_430" data-name="Group 430" transform="translate(-1290 -1780)">
                                    <g id="Ellipse_2" data-name="Ellipse 2" transform="translate(1290 1780)" fill="none" stroke="#fff" stroke-width="3">
                                        <circle cx="26.5" cy="26.5" r="26.5" stroke="none"/>
                                        <circle cx="26.5" cy="26.5" r="25" fill="none"/>
                                    </g>
                                    <g id="Group_23" data-name="Group 23" transform="translate(1302.162 1819.429) rotate(-90)">
                                        <g id="Group_13" data-name="Group 13">
                                            <line id="Line_1" data-name="Line 1" y2="26.138" transform="translate(11.59)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
                                            <path id="Path_36" data-name="Path 36" d="M0,0,11.59,11.59,23.181,0" transform="translate(0 17.086)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                            <p className="small bold">GRÜNER BLOG / GRÜNES LEBEN</p>
                        </Link>
                    </div>
                </div>*/}
            </ServiceContent>
        </Layout>
    )
}

export default Service;
